import React from 'react'
import { css } from '@emotion/react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { useLoadRemoteScript } from '../../../components/hooks/useLoadRemoteScript'
import { graph } from '../../../components/styles/graph'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const finance = css`
  @media screen and (min-width: 1200px) {
    .bg-white {
      width: calc(50% - 20px);
    }
  }
`

const Page = () => {
  // 表の埋め込みに使用。id属性とsrcを指定
  useLoadRemoteScript(
    '#fi01',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/9206a430/ea15/4bcf/bb3e/4ba1c534eb3d/table_ja.js'
  )
  useLoadRemoteScript(
    '#fi02',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/6177b110/f5c5/4d7d/9779/1724f7719997/table_ja.js'
  )
  useLoadRemoteScript(
    '#fi03',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/6fd72cac/baf2/4815/b353/97c778724d15/table_ja.js'
  )
  useLoadRemoteScript(
    '#fi04',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/0757a7b9/f65e/4c77/b119/45a95224b52d/table_ja.js'
  )
  return (
    <LayoutIR
      heading="財政状態"
      headingEnglish={ENGLISH_TITLE.FinancialPosition}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '業績・財務ハイライト', url: '/ir/performance' },
        { title: '財政状態', url: '/' },
      ]}
    >
      <div className="pc:flex pc:flex-wrap" css={finance}>
        <div className="pc:pt-12 pc:px-6 pc:mr-10 pc:pb-6 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="純資産" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/9206a430/ea15/4bcf/bb3e/4ba1c534eb3d/graph_ja.png"
              alt="純資産"
            />
            <div id="fi01" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:pb-6 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="総資産" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/6177b110/f5c5/4d7d/9779/1724f7719997/graph_ja.png"
              alt="総資産"
            />
            <div id="fi02" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:mr-10 pc:pb-6 mb-14 pc:mb-4 bg-white">
          <HeadingTertiary label="自己資本比率" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/6fd72cac/baf2/4815/b353/97c778724d15/graph_ja.png"
              alt="自己資本比率"
            />
            <div id="fi03" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（%）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:pb-6 mb-8 pc:mb-4 bg-white">
          <HeadingTertiary label="1株当たり純資産" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/0757a7b9/f65e/4c77/b119/45a95224b52d/graph_ja.png"
              alt="1株当たり純資産"
            />
            <div id="fi04" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs mr-10 pc:mr-8 pt-2">（円）</p>
          </div>
        </div>
        <p className="text-sm leading-normal">
          ご利用上の注意
          <br />
          ・決算短信等の訂正が発表された場合、本データの記載内容は即時反映されません。
          <br />
          ・一部の指標については、決算短信様式変更に伴い通期のみの更新となる場合があります。
        </p>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="財政状態" url="/ir/performance/finance" />
)
